import { useEffect, useState, useMemo, useContext } from "react";
import debounce from "lodash.debounce";
import { Link, useNavigate } from "react-router-dom";
import type { FC } from "react";
import { Loading, Input, Button, Toast } from "ui-atoms";
import { Table, Modal, StyledMarketSphereValue } from "ui-molecules";
import type { ModalProps } from "ui-molecules";
import { MODAL_TABLE_COLUMNS } from "./constants";
import type { PropertyMSProps } from "types";
import {
  getMarketsphereAPI,
  patchPropertyAPI,
  postPropertyMarketsphereAPI,
} from "services";
import { useApiCall } from "hooks";
import { GlobalContext } from "context";
import {
  EVENT_ADD_TO_MYLISTINGS,
  EVENT_IMPORT_FROM_MARKETSPHERE,
  EventProps,
  SET_EVENT_MODAL,
  SET_LOADING_MODAL,
  URLS,
} from "constant";
import React from "react";

interface PropertyModalProps {
  isOpen?: boolean;
  setIsOpen: (a: boolean) => void;
}

const PropertyModal: FC<PropertyModalProps> = ({
  isOpen = false,
  setIsOpen,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const [getMarketsphere, loading, MSData] = useApiCall(getMarketsphereAPI);
  const [postPropertyMarketsphere] = useApiCall(postPropertyMarketsphereAPI);
  const [patchProperty] = useApiCall(patchPropertyAPI);

  const navigate = useNavigate();
  const [modalSize, setModalSize] = useState<ModalProps["size"]>("large");
  const [data, setData] = useState<PropertyMSProps[]>([]);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    setKeyword("");
  }, [isOpen]);

  useEffect(() => {
    if (MSData?.data?.search?.propertySearchResults) {
      setData(MSData.data.search.propertySearchResults);
    }
  }, [MSData]);

  const updateKeywordFilter = (e: any) => {
    const value = e.target.value;
    setKeyword(value);
    if (value) {
      getMarketsphere({ keyword: value });
    }
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    []
  );

  const onChangeInput = (e: any) => {
    debounceUpdateKeyword(e);
  };

  const onClickAdd = (item: PropertyMSProps) => {
    const action = (event: EventProps) => {
      try {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            label: `Adding property to My Listing`,
          },
        });
        postPropertyMarketsphere({
          property_id: item.resultId,
          global_market: item.country.countryName.toLocaleLowerCase(),
          ...{ ...event },
        })
          .then((res: any) => {
            navigate(`/property/${res.property_id}/${URLS.PROPERTY.FORM}`);
          })
          .finally(() => {
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
          });
      } catch (err) {
        setTimeout(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              label: null,
            },
          });
        }, 100);
      }
    };
    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Import from MarketSphere",
          label: `What is reason for importing from MarketSphere?`,
          eventType: EVENT_IMPORT_FROM_MARKETSPHERE,
          buttonLabel: "Import from MarketSphere",
          action,
        },
      },
    });
  };

  const handleAddCoordinator = async (item: PropertyMSProps) => {
    const action = async (event: EventProps) => {
      try {
        const updatedCoordinators = [
          ...(item?.coordinators || []),
          session?.id,
        ];
        const response = await patchProperty({
          id: item?.resultId,
          payload: { coordinator: updatedCoordinators, ...{ ...event } },
        });
        if (!response) return;
        Toast.success("Added to MyListings");
        const newData: PropertyMSProps[] = data?.map((obj) => {
          if (obj?.resultId === item?.resultId) {
            return { ...obj, coordinators: updatedCoordinators };
          }
          return obj;
        });
        setData(newData);
      } catch (err) {}
    };
    dispatch({
      type: SET_EVENT_MODAL,
      payload: {
        open: true,
        content: {
          title: "Add to MyListings",
          label: `What is reason for adding this property to MyListings?`,
          eventType: EVENT_ADD_TO_MYLISTINGS,
          buttonLabel: "Add to MyListings",
          action,
        },
      },
    });
  };

  const handleEmail = () => {
    const recipient = "listingsupport@jll.com";
    const subject = "My Listings - Property Creation Request";
    const body =
      "Property Name:\n\nProperty Address:\n\nProperty Type (Office, Industrial, Retail, Land, Lab, Data, Flex R&D):\n\nCoStar ID:\n\nEntered as Opportunity in Aero (yes/no/unsure):\n\nAdditional Information:\n\n\n\n\n\n-----------\n\nThe Listing Support team will confirm when this Property has been created.  Thanks!";

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(mailtoLink);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size={modalSize}>
      {modalSize === "large" && (
        <>
          <Modal.Header>
            <h3 className="text-lg font-medium leading-6 text-gray-900 pb-4">
              Search for a property to import to My Listings
            </h3>
            <Input
              className="w-full"
              name="keyword"
              placeholder="Search a name"
              isClearable
              onChange={onChangeInput}
            />
          </Modal.Header>
          {!data?.length && loading && (
            <div className="py-6">
              <Loading />
            </div>
          )}
          {data && !!data.length && (
            <Modal.Body>
              {loading && (
                <div className="py-6">
                  <Loading />
                </div>
              )}
              {!loading && (
                <Table className="mt-4">
                  <Table.Thead>
                    <Table.Tr>
                      {MODAL_TABLE_COLUMNS.map((column, index) => {
                        return <Table.Th key={index}>{column.label}</Table.Th>;
                      })}
                      <Table.Th />
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {data?.map((item: PropertyMSProps, index: number) => {
                      return (
                        <Table.Tr key={index}>
                          {MODAL_TABLE_COLUMNS.map((column, index) => {
                            return (
                              <Table.Td
                                key={index}
                                className="truncate max-w-[200px]"
                              >
                                <div className="text-ellipsis overflow-hidden">
                                  <StyledMarketSphereValue
                                    property={item}
                                    valueKey={column.id}
                                  />
                                </div>
                              </Table.Td>
                            );
                          })}
                          <Table.Td className="space-x-2 text-right">
                            {item.added ? (
                              (
                                item?.coordinator || item?.coordinators
                              )?.includes(session?.id) ? (
                                <Link
                                  to={`/property/${item.resultId}/${URLS.PROPERTY.FORM}`}
                                >
                                  <Button
                                    variant="secondary"
                                    size="small"
                                    trailingIconClass="w-5 h-5"
                                  >
                                    Go to My Listing
                                  </Button>
                                </Link>
                              ) : (
                                <Button
                                  variant="secondary"
                                  size="small"
                                  trailingIconClass="w-5 h-5"
                                  onClick={() => handleAddCoordinator(item)}
                                >
                                  Add to My Listings
                                </Button>
                              )
                            ) : (
                              <Button
                                variant="primary"
                                size="small"
                                trailingIconClass="w-5 h-5"
                                onClick={() => onClickAdd(item)}
                              >
                                Import from MarketSphere
                              </Button>
                            )}
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table.Tbody>
                </Table>
              )}
            </Modal.Body>
          )}
          {!loading && !!keyword?.length && (
            <div className="mt-5 flex justify-center">
              <p className="text-sm text-jll-color-coldGray-7">
                Don't see your Property?{" "}
                <a
                  type="button"
                  className="text-jll-color-icon-info cursor-pointer"
                  onClick={handleEmail}
                >
                  Please click here to request Property creation
                </a>
              </p>
            </div>
          )}
        </>
      )}
      {modalSize === "large" && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default React.memo(PropertyModal);
