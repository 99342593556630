import DeliverableBgImg from "assets/deliverable_bg.png";
import BgImg from "assets/homepage_bg.jpg";
import JllLogo from "assets/jll-logo-white.svg";
import { useApiCall, useWindowDimensions } from "hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import { getTeamListingDeliverableLinkAPI } from "services/deliverable";
import { TeamListingLinkProps, TeamListingProps } from "types";
import { Avatar, Loading } from "ui-atoms";
import cn from "classnames";
import {
  DeliverablePDF,
  GoogleMap,
  StyledDeliverableValue,
  Table,
} from "ui-molecules";
import { TABLE_COLUMNS } from "./constants";
import {
  capitalizeSymbolWords,
  capitalizeWords,
  convertExtension,
  getDecimalFormating,
  lazyLoadImageOnError,
  reorder,
} from "utils";
import SlidingPane from "react-sliding-pane";
import DeliverableDetailPane from "./DeliverableDetailPane";
import React from "react";
import {
  convertImageUrl,
  patchTeamListingLinkAPI,
  putTeamListingLinkAPI,
} from "services";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const SORT_COUNT = 1;
const SORT_ALPHA = 2;
const SORT_CUSTOM = 3;

const DeliverablePage: FC = () => {
  const { uuid } = useParams();
  const [getTeamListingDeliverableLink] = useApiCall(
    getTeamListingDeliverableLinkAPI
  );
  const [patchTeamListingLink] = useApiCall(patchTeamListingLinkAPI);
  const [teamListing, setTeamListing] = useState<TeamListingProps | null>(null);
  const [teamListingLink, setTeamListingLink] =
    useState<TeamListingLinkProps | null>(null);
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [buildingData, setBuildingData] = useState<any>([]);
  const [avgRent, setAvgRent] = useState<any>(null);
  const [sumSpace, setSumSpace] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState<any>(undefined);
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pdfTemplate, setPdfTemplate] = useState<any>(null);
  const [groups, setGroups] = useState<any>([]);

  useEffect(() => {
    if (!uuid) return;
    setIsLoading(true);
    getTeamListingDeliverableLink({ uuid })
      .then((res: any) => {
        setTeamListing(res?.team_listing);
        setTeamListingLink(res?.team_listing_link);

        if (
          !!res?.team_listing_link?.breakdown &&
          !!res?.team_listing?.groups
        ) {
          let gs: any = [];
          switch (res?.team_listing_link?.breakdown_sort) {
            case SORT_ALPHA:
              gs = Object.keys(res?.team_listing?.groups || {})?.sort();
              break;

            case SORT_CUSTOM:
              const temp = Object.keys(res?.team_listing?.groups || {});
              if (!res?.team_listing_link?.breakdown_custom_sort?.length) {
                gs = temp;
              } else {
                let custom: any = [],
                  rest: any = [];
                res?.team_listing_link?.breakdown_custom_sort?.forEach(
                  (item: string) => {
                    if (temp?.includes(item)) {
                      custom.push(item);
                    } else {
                      rest.push(item);
                    }
                  }
                );
                gs = [...custom, ...rest];
              }
              break;

            default:
              gs = Object.keys(res?.team_listing?.groups || {})?.sort((a, b) =>
                res?.team_listing?.groups?.[b].listings?.length >
                res?.team_listing?.groups?.[a].listings?.length
                  ? 1
                  : res?.team_listing?.groups?.[a].listings?.length >
                    res?.team_listing?.groups?.[b].listings?.length
                  ? -1
                  : 0
              );
          }
          const keys = gs?.map((key: string) => key) || [];
          setGroups(gs);
          setActiveTab(keys?.[0] || "");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [uuid]);

  useEffect(() => {
    if (!teamListing || !teamListingLink) return;
    if (!!teamListingLink?.breakdown) {
      if (activeTab) {
        setBuildingData(teamListing?.groups?.[activeTab]?.["listings"]);
        setAvgRent(teamListing?.groups?.[activeTab]?.["avg_rent"]);
        setSumSpace(teamListing?.groups?.[activeTab]?.["sum_space"]);
      } else {
        setBuildingData([]);
        setAvgRent(null);
        setSumSpace(null);
      }
    } else {
      setBuildingData(teamListing?.listings || []);
      setAvgRent(teamListing?.avg_rent);
      setSumSpace(teamListing?.sum_space);
    }
  }, [activeTab, teamListingLink, teamListing]);

  useEffect(() => {
    if (!teamListing || !teamListingLink) return;
    const layout: any = DeliverablePDF({
      teamListing,
      teamListingLink,
    });
    setPdfTemplate(layout);
  }, [teamListing, teamListingLink]);

  useEffect(() => {
    if (width <= 768) setIsMobile(true);
    else setIsMobile(false);
  }, [width]);

  const onDragEnd = (result: any) => {
    try {
      if (!result.destination) {
        return;
      }
      const newOrder = reorder(
        [...groups],
        result.source.index,
        result.destination.index
      );

      const payload = {
        pk: teamListingLink?.id,
        breakdown_custom_sort: newOrder,
      };
      setGroups(newOrder);
      patchTeamListingLink(payload);
    } catch (err) {}
  };

  if (isLoading)
    return (
      <div className="fixed top-0 right-0 bottom-0 left-0">
        <Loading />
      </div>
    );

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>
          JLL -{" "}
          {teamListingLink?.title || teamListingLink?.team_listing_name || ""}
        </title>
        <meta
          property="og:title"
          content={teamListingLink?.description || ""}
        />
      </Helmet>
      {/* Desktop Header */}
      <section className="w-full relative min-h-[570px] h-fit mb-6 hidden md:block">
        <LazyLoadImage
          src={DeliverableBgImg}
          className="w-full absolute top-0 left-0 right-0 min-h-[535px] object-fill z-[-1] h-max"
          onError={lazyLoadImageOnError}
        />
        <div className="mx-8 flex py-8 mb-8">
          <div className="flex flex-row items-center space-x-3">
            <img src={JllLogo} className="h-7" />
            <span className="font-semibold text-white">Listings</span>
          </div>
        </div>
        <div className="mx-auto 2xl:max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md sm:max-w-screen-sm px-4 flex flex-col h-fit min-h-[350px]">
          <div className="w-6/12 flex flex-col justify-around h-full min-h-[350px]">
            <p className="text-5xl text-white font-semibold line-clamp-2 leading-[55px] h-full">
              {teamListingLink?.title ||
                teamListingLink?.team_listing_name ||
                ""}
            </p>
            <p className="text-jll-color-coldGray-4 text-2xl line-clamp-2 leading-[55px] h-full">
              {teamListingLink?.description || ""}
            </p>

            <div className="flex flex-row h-full">
              <div className="w-full flex flex-row items-center flex-wrap">
                {(!!teamListingLink?.authors?.length ||
                  !!teamListingLink?.author?.length) &&
                  (teamListingLink?.authors || teamListingLink?.author)?.map(
                    (author, idx) => (
                      <div
                        key={idx}
                        className={cn(
                          "flex space-x-2 items-center mr-5 py-2 mb-2",
                          {
                            "border-r border-r-jll-color-coldGray-4 pr-5":
                              idx <
                              (
                                teamListingLink?.authors ||
                                teamListingLink?.author ||
                                []
                              )?.length -
                                1,
                          }
                        )}
                      >
                        <Avatar
                          alt=""
                          size="default"
                          src={author?.broker?.photo || ""}
                          name={[
                            author?.user_first_name,
                            author?.user_last_name,
                          ]?.join(" ")}
                        />
                        <div className="truncate w-[155px]">
                          <p
                            className="font-semibold text-white text-ellipsis overflow-hidden"
                            title={
                              (
                                [
                                  author?.user_first_name,
                                  author?.user_last_name,
                                ]?.join(" ") || ""
                              )?.length > 15
                                ? [
                                    author?.user_first_name,
                                    author?.user_last_name,
                                  ]?.join(" ") || ""
                                : undefined
                            }
                          >
                            {[
                              author?.user_first_name,
                              author?.user_last_name,
                            ]?.join(" ") || ""}
                          </p>
                          <p
                            className="whitespace-normal text-sm text-jll-color-coldGray-4 truncate"
                            title={
                              author?.user_email?.length > 18
                                ? author?.user_email
                                : undefined
                            }
                          >
                            {author?.user_email || ""}
                          </p>
                          <p className="whitespace-normal text-sm text-jll-color-coldGray-4 truncate">
                            {author?.broker?.telephone || ""}
                          </p>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-6/12 absolute right-0 top-0 bottom-[0px] pl-8">
          <div className="w-full h-[570px] relative">
            <LazyLoadImage
              src={
                !!teamListingLink?.images?.length
                  ? convertImageUrl(
                      convertExtension(teamListingLink?.images[0])
                    )
                  : BgImg
              }
              className="w-full h-full object-cover rounded-bl-3xl"
              onError={lazyLoadImageOnError}
            />
          </div>
        </div>
      </section>

      {/* Mobile Header */}
      <section className="w-full relative h-[570px] block md:hidden mobile-header">
        <LazyLoadImage
          src={
            !!teamListingLink?.images?.length
              ? convertImageUrl(convertExtension(teamListingLink?.images[0]))
              : BgImg
          }
          className="w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover z-[-1]"
          onError={lazyLoadImageOnError}
        />
        <div className="px-5 flex py-4 flex-row justify-between space-x-2">
          <div className="flex flex-row items-center h-fit space-x-3">
            <img src={JllLogo} className="h-7" />
            <span className="font-semibold text-white">Listings</span>
          </div>
          <div className="flex flex-col items-end">
            {(!!teamListingLink?.authors?.length ||
              !!teamListingLink?.author?.length) &&
              (teamListingLink?.authors || teamListingLink?.author)
                ?.slice(0, 4)
                ?.map((author, idx) => (
                  <div key={idx} className="flex space-x-2 items-center py-2">
                    <div className="truncate max-w-[180px]">
                      <p className="font-semibold text-white text-ellipsis overflow-hidden text-sm text-end">
                        {[
                          author?.user_first_name,
                          author?.user_last_name,
                        ]?.join(" ")}
                      </p>
                      <p className="whitespace-normal text-xs text-jll-color-coldGray-4 truncate text-end">
                        {author?.user_email}
                      </p>
                    </div>
                    <Avatar
                      alt=""
                      size="small"
                      className="w-8 h-8"
                      src={author?.broker?.photo || ""}
                      name={[
                        author?.user_first_name,
                        author?.user_last_name,
                      ]?.join(" ")}
                    />
                  </div>
                ))}
          </div>
        </div>

        <div className="absolute bottom-[40px] w-full px-5">
          <p className="text-3xl text-white font-semibold line-clamp-2 mb-2">
            {teamListingLink?.title || teamListingLink?.team_listing_name || ""}
          </p>
          <p className="text-jll-color-coldGray-4 text-xl line-clamp-2 mb-6">
            {teamListingLink?.description || ""}
          </p>
        </div>
      </section>

      <section className="mx-auto 2xl:max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md sm:max-w-screen-sm md:px-4 px-5 pb-8 md:mt-0 mt-[-16px] rounded-t-lg md:rounded-none bg-white">
        {!!teamListingLink?.breakdown && !!groups?.length && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  className="w-full flex flex-row space-x-8 border-b border-b-jll-color-coldGray-3 mb-8"
                  {...provided.droppableProps}
                  ref={provided?.innerRef}
                >
                  {groups?.map((tab: any, idx: number) => (
                    <Draggable
                      key={idx}
                      draggableId={`tab-${idx}`}
                      index={idx}
                      isDragDisabled={
                        teamListingLink?.breakdown_sort !== SORT_CUSTOM
                      }
                    >
                      {(provided, snapshot) => (
                        <div
                          className={cn(
                            "py-8 cursor-pointer md:w-fit w-full md:text-left text-center flex flex-row items-center space-x-2 !cursor-pointer bg-white",
                            {
                              "border-b border-b-jll-color-text-base-default":
                                tab === activeTab,
                            }
                          )}
                          onClick={() => setActiveTab(tab)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <span
                            className={cn("text-text-xl font-semibold", {
                              "text-jll-color-coldGray-5": tab !== activeTab,
                              "text-jll-color-text-base-default":
                                tab === activeTab,
                            })}
                          >
                            {capitalizeWords(
                              capitalizeWords(
                                capitalizeSymbolWords(tab, "&"),
                                "-"
                              )
                            )}
                          </span>
                          <div className="bg-jll-color-coldGray-1 rounded-full text-sm px-2 py-0.5 flex justify-center items-center font-semibold text-jll-color-text-base-default">
                            {teamListing?.groups?.[tab].listings?.length || 0}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

        {!!buildingData?.length && (
          <div className="md:px-16 md:py-6 py-4 bg-jll-color-coldGray-1 md:rounded-3xl rounded-3xl flex flex-row justify-around divide-x divide-black divide-opacity-[0.1] md:mb-8 mb-6">
            <div className="flex flex-col items-center justify-center w-full">
              <span className="md:text-[32px] text-xl font-semibold text-jll-color-text-base-default md:mb-2">
                {buildingData?.length || ""}
              </span>
              <span className="text-sm text-jll-color-text-base-subdued text-center">
                Building Count
              </span>
            </div>

            <div className="flex flex-col items-center justify-center w-full">
              <span className="md:text-[32px] text-xl font-semibold text-jll-color-text-base-default md:mb-2">
                {sumSpace ? `${getDecimalFormating(sumSpace)}` : "N/A"}
              </span>
              <span className="text-sm text-jll-color-text-base-subdued text-center">
                Total RSF Available
              </span>
            </div>
            <div className="flex flex-col items-center justify-center w-full">
              <span className="md:text-[32px] text-xl font-semibold text-jll-color-text-base-default md:mb-2">
                {avgRent
                  ? `$${getDecimalFormating(avgRent?.toFixed(2))}`
                  : "N/A"}
              </span>
              <span className="text-sm text-jll-color-text-base-subdued text-center">
                Avg. Base Rate
              </span>
            </div>
          </div>
        )}

        {!!buildingData?.length && (
          <div className="flex md:h-[300px] h-[400px] md:rounded-lg truncate md:mb-8 mb-6 md:mx-0 mx-[-20px]">
            <GoogleMap
              locations={
                buildingData?.map((building: any) => {
                  return {
                    lat: building?.latitude,
                    lng: building?.longitude,
                    ...building,
                  };
                }) || []
              }
              zoom={8}
              className="h-full"
              isTooltip
              setIsOpenPanel={setIsOpen}
              setSelectedBuilding={setSelectedBuilding}
            />
          </div>
        )}

        {!!buildingData?.length && (
          <div className="overflow-x-auto">
            <Table>
              <Table.Thead>
                <Table.Tr>
                  {TABLE_COLUMNS.map((column, idx) => (
                    <Table.Th key={idx}>{column?.label}</Table.Th>
                  ))}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {!!buildingData?.length &&
                  buildingData?.map((building: any, idx: number) => (
                    <Table.Tr
                      key={idx}
                      onClick={() => {
                        setIsOpen(true);
                        setSelectedBuilding({ building, index: idx });
                      }}
                    >
                      {TABLE_COLUMNS.map((column, idx1) => (
                        <Table.Td key={idx1}>
                          <StyledDeliverableValue
                            building={building}
                            valueKey={column.id}
                            index={idx}
                          />
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table>
          </div>
        )}
      </section>

      <SlidingPane
        width={isMobile ? "100%" : "75%"}
        from={isMobile ? "bottom" : "right"}
        hideHeader={true}
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
          setSelectedBuilding(undefined);
        }}
        className="md:rounded-none rounded-2xl deliverable-page"
      >
        {selectedBuilding && (
          <DeliverableDetailPane
            building={selectedBuilding?.building}
            index={selectedBuilding?.index}
            suites={
              (selectedBuilding &&
                teamListing?.suites?.filter(
                  (suite) => suite?.building == selectedBuilding?.building?.pk
                )) ||
              []
            }
          />
        )}
      </SlidingPane>
    </div>
  );
};

export default React.memo(DeliverablePage);
