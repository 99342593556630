import { useState, useContext, useEffect, useMemo } from "react";
import { Button, Input, SelectAutoComplete, Toast } from "ui-atoms";
import { Table, Modal, StyledBrokerValue, ShowMoreItems } from "ui-molecules";
import { FiSave, FiSearch } from "react-icons/fi";
import { getMetaOptions } from "utils";
import { GlobalContext } from "context";
import { useParams } from "react-router-dom";
import { BROKERS_TABLE_COLUMNS, STATUS_ACTIVE } from "constant";
import { SEARCH_RESULT_LIMIT } from "./constants";
import type { BrokerProps } from "types";
import {
  getBrokersAPI,
  getRecentBrokersAPI,
  patchAvailabilityAPI,
  patchPropertyAPI,
  postAvailabilityBrokerAPI,
  postPropertyBrokerAPI,
} from "services";
import { useApiCall } from "hooks";
import debounce from "lodash.debounce";

interface ContactModalProps {
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
  onClick: (broker: any) => void;
  meta: any;
  isProperty: boolean;
}

const ContactModal = ({
  isOpen,
  setIsOpen,
  onClick,
  meta,
  isProperty = true,
}: ContactModalProps) => {
  const [getBrokers, loading] = useApiCall(getBrokersAPI);
  const [getRecentBrokers] = useApiCall(getRecentBrokersAPI);
  const [postBroker] = useApiCall(
    isProperty ? postPropertyBrokerAPI : postAvailabilityBrokerAPI
  );
  const [patchProperty] = useApiCall(patchPropertyAPI);
  const [patchAvailability] = useApiCall(patchAvailabilityAPI);
  const { state } = useContext(GlobalContext);
  const { property, availability } = state;
  const { availabilityId } = useParams();
  const [brokers, setBrokers] = useState<any[]>([]);
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState<string>();
  const [recent, setRecent] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setKeyword("");

      getRecentBrokers({
        page: 1,
        limit: SEARCH_RESULT_LIMIT,
      }).then((data: any) => {
        setRecent(!data?.empty);
        setBrokers(data?.docs);
        setPage(data?.page);
        setTotal(data?.total);
      });
    }
  }, [isOpen]);

  const onClickShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);

    if (keyword) {
      getBrokers({
        page: nextPage,
        limit: SEARCH_RESULT_LIMIT,
        active_status: [STATUS_ACTIVE],
        keyword,
      }).then((data: any) => {
        setRecent(false);
        setBrokers((prevData: any) => [...prevData, ...data?.docs]);
        setPage(data?.page);
        setTotal(data?.total);
      });
    } else {
      getRecentBrokers({
        page: nextPage,
        limit: SEARCH_RESULT_LIMIT,
      }).then((data: any) => {
        setRecent(!data?.empty);
        setBrokers((prevData: any) => [...prevData, ...data?.docs]);
        setPage(data?.page);
        setTotal(data?.total);
      });
    }
  };

  const updateKeywordFilter = (e: any) => {
    const value = e.target.value;
    setKeyword(value);
    if (!!value)
      getBrokers({
        page: 1,
        limit: SEARCH_RESULT_LIMIT,
        active_status: [STATUS_ACTIVE],
        keyword: value,
      }).then((data: any) => {
        setRecent(false);
        setBrokers(data?.docs);
        setPage(data?.page);
        setTotal(data?.total);
      });
    else
      getRecentBrokers({
        page: 1,
        limit: SEARCH_RESULT_LIMIT,
      }).then((data: any) => {
        setRecent(!data?.empty);
        setBrokers(data?.docs);
        setPage(data?.page);
        setTotal(data?.total);
      });
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    []
  );

  const onChangeInput = (e: any) => {
    debounceUpdateKeyword(e);
  };

  const onChangeRole = (brokerId: number, role: string) => {
    const updatedContacts = [...brokers].map((broker) => {
      if (broker.pk === brokerId) {
        return {
          ...broker,
          role,
        };
      }
      return broker;
    });
    setBrokers(updatedContacts);
    setErrors({
      ...errors,
      [brokerId]: "",
    });
  };

  const onClickAdd = (e: any, broker: BrokerProps) => {
    e.stopPropagation();
    if (broker.role) {
      let payload: any = {
        broker_id: broker.pk,
      };
      if (isProperty) {
        payload = {
          ...payload,
          role: broker.role,
          entity_id: property.id,
        };
      } else {
        payload = {
          ...payload,
          role: broker.role,
          entity_id: Number(availabilityId),
        };
      }
      postBroker(payload).then((res: any) => {
        if (!res?.id) {
          setIsOpen(false);
          return;
        }
        Toast.success("Save with success!");
        if (isProperty) {
          onClick({
            ...broker,
            id: res.broker,
            building_broker_role: res.role,
            building_broker_id: res.id,
          });
        } else {
          onClick({
            ...broker,
            id: res.broker,
            suite_broker_role: res.role,
            suite_broker_id: res.id,
          });
        }
        if (isProperty && property?.publish_status === STATUS_ACTIVE)
          patchProperty({
            id: property?.id,
            payload: { publish_status: STATUS_ACTIVE },
          });
        if (!isProperty && availability?.publish_status === STATUS_ACTIVE)
          patchAvailability({
            id: availability?.id,
            payload: { publish_status: STATUS_ACTIVE },
          });
        setIsOpen(false);
      });
    } else {
      setErrors({
        ...errors,
        [broker.pk]: " ",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="large">
      <Modal.Header>
        <Input
          className="w-full mb-0"
          name="search"
          leadingIcon={FiSearch}
          onChange={onChangeInput}
          isClearable
          placeholder="Search by name, title, email"
        />
      </Modal.Header>
      <Modal.Body>
        {!!brokers?.length && !loading && (
          <div>
            {!!recent && (
              <div className="flex items-center mt-4">
                <FiSave className="w-4 h-4 text-jll-color-coldGray-5 mr-2" />
                Viewing brokers previously referenced.
              </div>
            )}
            <Table>
              <Table.Thead>
                <Table.Tr>
                  {BROKERS_TABLE_COLUMNS.map((column, index) => {
                    return <Table.Th key={index}>{column.label}</Table.Th>;
                  })}
                  <Table.Th>Role</Table.Th>
                  <Table.Th />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {brokers?.map((broker: BrokerProps, index: number) => {
                  const brokers = isProperty
                    ? property?.brokers
                    : availability?.brokers;
                  const existingBroker = brokers?.find(
                    (x: BrokerProps) => x.id === broker.pk
                  );
                  return (
                    <Table.Tr key={index}>
                      {BROKERS_TABLE_COLUMNS.map((column, index) => {
                        return (
                          <Table.Td
                            key={index}
                            className="truncate max-w-[230px]"
                          >
                            <StyledBrokerValue
                              broker={broker}
                              valueKey={column.id}
                            />
                          </Table.Td>
                        );
                      })}
                      <Table.Td className="w-[200px]">
                        {existingBroker &&
                          (existingBroker?.role ||
                            existingBroker?.suite_broker_role ||
                            existingBroker?.building_broker_role ||
                            "")}
                        {!existingBroker && (
                          <SelectAutoComplete
                            name="role"
                            className="!mb-0"
                            options={[
                              ...getMetaOptions(
                                isProperty
                                  ? meta?.buildingbroker?.role
                                  : meta?.suitebroker?.role
                              ),
                            ]}
                            placeholder="Select Role"
                            onChange={(option) => {
                              if (!option) onChangeRole(broker.pk, "");
                              else onChangeRole(broker.pk, option.value);
                            }}
                            // @ts-ignore fix typing issue
                            error={errors?.[broker.pk]}
                            isDisabled={!!existingBroker}
                          />
                        )}
                      </Table.Td>
                      <Table.Td className="text-right">
                        <Button
                          variant="secondary"
                          size="small"
                          onClick={(e) => onClickAdd(e, broker)}
                          disabled={!!existingBroker}
                        >
                          {!!existingBroker ? "Added" : "Add"}
                        </Button>
                      </Table.Td>
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            </Table>
            <ShowMoreItems
              onClick={onClickShowMore}
              total={total}
              isLoading={loading}
              itemsCount={brokers.length}
            />
          </div>
        )}
        {!brokers?.length && !loading && !!keyword?.length && (
          <div className="pt-6 pb-2 flex justify-center px-4">
            <p className="text-base text-jll-color-coldGray-7 text-center">
              To create a broker profile in{" "}
              <a
                href="https://www.us.jll.com/en/people"
                className="text-jll-color-icon-info"
                target="_blank"
              >
                JLL People Finder
              </a>
              , please visit{" "}
              <a
                href="https://profiles.jll.com/"
                target="_blank"
                className="text-jll-color-icon-info"
              >
                Profile Management
              </a>
              .
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactModal;
