import {
  InitialValuesProps,
  META_TAGS,
  OLM_PROPERTY_FIELDS,
  PROPERTY_INITIAL_VALUES,
  PROPERTY_TABS,
  PROPERTY_VALIDATION,
  SET_CONFIRM_MODAL,
  SET_LOADING_MODAL,
  SET_PROPERTY,
  STATUS_ACTIVE,
  URLS,
} from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall, useCallbackPrompt } from "hooks";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { getMarketsAPI, patchPropertyAPI } from "services";
import {
  Button,
  Checkbox,
  Input,
  Label,
  SelectAutoComplete,
  Switch,
  Toast,
} from "ui-atoms";
import {
  AccordionItem,
  GoogleMap,
  OLMFilterItem,
  RemoveDescriptionItem,
  WarningModal,
} from "ui-molecules";
import {
  filterStatesByGlobalMarket,
  getApiPayloadFromForm,
  getMetaNumOptions,
  getMetaOptions,
  getOptions,
  isCommonItem,
} from "utils";
import { LayoutEditProperty as Layout } from "ui-organisms";
import { FiChevronRight, FiMapPin } from "react-icons/fi";
import React from "react";

interface IPropertyEdit {
  onDiffFields?: any;
  diffFields?: any;
  handlePublish?: any;
  isOLM?: boolean;
}

const PropertyEditV2 = forwardRef(
  ({ onDiffFields, diffFields, handlePublish, isOLM }: IPropertyEdit, ref) => {
    const { dispatch, state } = useContext(GlobalContext);
    const { property, meta, showTooltip, hideTooltip } = state;
    const { propertyId } = useParams();
    const [patchProperty] = useApiCall(patchPropertyAPI);
    const [getMarkets] = useApiCall(getMarketsAPI);
    const [loading, setLoading] = useState(false);
    const [isWarningModal, setIsWarningModal] = useState(false);
    const [selectedMarketOption, setSelectedMarketOption] =
      useState<any>(undefined);
    const publishStatus = useRef<any>(null);
    const publishAvailabilities = useRef<any>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showPrompt, confirmNavigation, cancelNavigation] =
      useCallbackPrompt(showDialog);
    const [isAcresRange, setIsAcresRange] = useState(
      !!property?.min_surface_area
    );

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      setFieldValue,
      values,
      errors,
      touched,
      setValues,
    } = useFormik({
      initialValues: PROPERTY_INITIAL_VALUES,
      validationSchema: PROPERTY_VALIDATION,
      onSubmit: async () => {
        try {
          setLoading(true);
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: true,
              label: "Updating Property",
            },
          });
          hideTooltip();
          let payload: InitialValuesProps | undefined = getApiPayloadFromForm(
            property,
            values
          );
          if (payload && Object.keys(payload)?.length) {
            if (
              payload &&
              property?.publish_status === STATUS_ACTIVE &&
              isCommonItem(Object.keys(payload), OLM_PROPERTY_FIELDS)
            ) {
              payload["publish_status"] = STATUS_ACTIVE;
            }
            const response = await patchProperty({ id: property.id, payload });
            setLoading(false);
            publishStatus.current = null;
            publishAvailabilities.current = false;
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
            if (!response) return;
            Toast.success("Changes saved with success");
            dispatch({
              type: SET_PROPERTY,
              payload: response,
            });
          } else {
            Toast.warn("No changes to be saved");
            setLoading(false);
            publishStatus.current = null;
            publishAvailabilities.current = false;
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  label: null,
                },
              });
            }, 100);
          }
        } catch (err) {
          setLoading(false);
          publishStatus.current = null;
          publishAvailabilities.current = false;
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                label: null,
              },
            });
          }, 100);
        }
      },
    });

    const onClickSubmit = async (
      publish_status: any = null,
      publish_availabilities: boolean = false
    ) => {
      if (errors && !!Object.keys(errors)?.length) {
        setIsWarningModal(true);
      }
      publishStatus.current = publish_status;
      publishAvailabilities.current = publish_availabilities;
      await handleSubmit();
    };

    useImperativeHandle(ref, () => ({
      onClickSubmit,
    }));

    useEffect(() => {
      if (
        property &&
        !values.id &&
        property?.id?.toString() === propertyId?.toString()
      ) {
        let formValues = {};
        Object.keys(PROPERTY_INITIAL_VALUES).forEach((key: string) => {
          formValues = {
            ...formValues,
            [key]: property[key],
          };
        });

        setValues({
          ...formValues,
          property_type: property?.property_type,
          property_sub_types: getOptions(
            property?.property_sub_types,
            meta?.building?.property_sub_types
          ),
          tenure_types: property?.tenure_types || [],
          publish_status: property?.publish_status,
        });
        if (property?.market) {
          setSelectedMarketOption({
            value: property?.market,
            label: property?.market_name,
          });
        }
      }
    }, [property, values, propertyId]);

    useEffect(() => {
      if (!property || !values) return;
      const diff = getApiPayloadFromForm(property, values);
      onDiffFields(diff);
    }, [property, values]);

    useEffect(() => {
      if (!diffFields || !Object.keys(diffFields)?.length) setShowDialog(false);
      else setShowDialog(true);
    }, [diffFields]);

    useEffect(() => {
      if (!showPrompt) return;
      const action = async () => {
        try {
          if (errors && !!Object.keys(errors)?.length) {
            setIsWarningModal(true);
            // @ts-ignore
            cancelNavigation();
            return;
          }
          await handleSubmit();
          // @ts-ignore
          confirmNavigation();
        } catch (err) {
          // @ts-ignore
          cancelNavigation();
        }
      };
      let isPublish = false;
      if (
        diffFields &&
        property?.publish_status === STATUS_ACTIVE &&
        isCommonItem(Object.keys(diffFields), OLM_PROPERTY_FIELDS)
      ) {
        isPublish = true;
      }

      dispatch({
        type: SET_CONFIRM_MODAL,
        payload: {
          open: true,
          content: {
            description: renderToString(
              <RemoveDescriptionItem description="You have un-saved changes, would you like to save changes before proceeding" />
            ),
            btnTitle: `${isPublish ? "Save and Publish" : "Save"}`,
            cancelTitle: "Proceed without Saving",
            action: action,
            cancel: confirmNavigation,
            additionalCancel: cancelNavigation,
          },
        },
      });
    }, [showPrompt]);

    const loadMarketOptions = async (keyword: string) => {
      return getMarkets({ keyword: keyword }).then((res: any) => {
        return res.docs.map((market: any) => {
          return {
            value: market?.pk,
            label: market?.name,
          };
        });
      });
    };

    const isLand = values?.property_type === "land";

    return (
      <>
        <Helmet prioritizeSeoTags>
          <title>{`${META_TAGS?.default?.title} ${
            property?.title || property?.address
          } Edit`}</title>
          <meta
            property="og:title"
            content={`${META_TAGS?.default?.title} ${
              property?.title || property?.address
            } Edit`}
          />
        </Helmet>

        {!!values.id && (
          <form>
            <Layout.Section>
              <AccordionItem id="location" label="Location">
                <div className="flex flex-row space-x-4 pt-8">
                  <div className="w-9/12 flex flex-col">
                    <OLMFilterItem
                      fieldKey="title"
                      olmFields={OLM_PROPERTY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6" optional={true}>
                          Building name
                        </Label>
                        <Input
                          name="title"
                          className="w-full"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.title}
                          error={touched.title ? errors.title : ""}
                        />
                      </div>
                    </OLMFilterItem>

                    <OLMFilterItem
                      fieldKey="market"
                      olmFields={OLM_PROPERTY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6">Market</Label>
                        <SelectAutoComplete
                          name="market"
                          className="w-full"
                          onChange={(option) => {
                            if (!option) {
                              setFieldValue("market", null);
                              setSelectedMarketOption(undefined);
                            } else {
                              setFieldValue("market", option.value);
                              setSelectedMarketOption(option);
                            }
                          }}
                          onBlur={handleBlur}
                          value={selectedMarketOption}
                          error={touched.market ? errors.market : ""}
                          loadOptions={loadMarketOptions}
                        />
                      </div>
                    </OLMFilterItem>

                    <OLMFilterItem
                      fieldKey="address"
                      olmFields={OLM_PROPERTY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6">Address</Label>
                        <Input
                          className="w-full"
                          name="address"
                          trailingIcon={FiMapPin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.address}
                          error={touched.address ? errors.address : ""}
                          required
                        />
                      </div>
                    </OLMFilterItem>

                    <OLMFilterItem
                      fieldKey="city"
                      olmFields={OLM_PROPERTY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6">City</Label>
                        <Input
                          className="w-full"
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.city}
                          error={touched.city ? errors.city : ""}
                        />
                      </div>
                    </OLMFilterItem>

                    <OLMFilterItem
                      fieldKey="state"
                      olmFields={OLM_PROPERTY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6">{`State${
                          property.global_market == "ca" ? `(Province)` : ""
                        }`}</Label>
                        <SelectAutoComplete
                          name="state"
                          className="w-full"
                          onChange={(option) => {
                            if (!option) setFieldValue("state", null);
                            else setFieldValue("state", option.value);
                          }}
                          onBlur={handleBlur}
                          value={{
                            value: values?.state,
                            label: values?.state,
                          }}
                          error={touched.state ? errors.state : ""}
                          options={filterStatesByGlobalMarket(
                            [...getMetaOptions(meta?.building?.state)],
                            property?.global_market
                          )}
                        />
                      </div>
                    </OLMFilterItem>

                    <OLMFilterItem
                      fieldKey="post_code"
                      olmFields={OLM_PROPERTY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6">Zip Code</Label>
                        <Input
                          className="w-full"
                          name="post_code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.post_code}
                          error={touched.post_code ? errors.post_code : ""}
                        />
                      </div>
                    </OLMFilterItem>

                    <OLMFilterItem
                      fieldKey="latitude"
                      olmFields={OLM_PROPERTY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6">Latitude</Label>
                        <Input
                          className="w-full"
                          name="latitude"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.latitude}
                          error={touched.latitude ? errors.latitude : ""}
                        />
                      </div>
                    </OLMFilterItem>

                    <OLMFilterItem
                      fieldKey="longitude"
                      olmFields={OLM_PROPERTY_FIELDS}
                      isOLM={isOLM}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <Label className="w-6/12 mb-6">Longitude</Label>
                        <Input
                          className="w-full"
                          name="longitude"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.longitude}
                          error={touched.longitude ? errors.longitude : ""}
                        />
                      </div>
                    </OLMFilterItem>
                  </div>
                  <div className="w-3/12 flex flex-col">
                    <Label className="mb-6">Building on map</Label>
                    <GoogleMap
                      position={{
                        lat: Number(values?.latitude),
                        lng: Number(values?.longitude),
                      }}
                      className="rounded md:h-full mb-6 overflow-hidden"
                      zoom={13}
                    />
                  </div>
                </div>
              </AccordionItem>
            </Layout.Section>

            <Layout.Section>
              <AccordionItem id="detail" label="Details">
                <div className="flex flex-col w-full pt-8">
                  <OLMFilterItem
                    fieldKey="tenure_types"
                    olmFields={OLM_PROPERTY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center w-full space-x-2">
                      <Label className="w-4/12 mb-6">Listing Type</Label>
                      <div className="flex flex-row items-center w-full space-x-4 mb-6">
                        {[...getMetaOptions(meta?.building?.tenure_types)]?.map(
                          (option, idx) => (
                            <Checkbox
                              key={idx}
                              id={`tenure-${idx}`}
                              name={option?.value}
                              label={option?.label}
                              labelClassName="font-semibold"
                              className="!space-x-2"
                              value={option.value}
                              checked={
                                !!values?.tenure_types?.includes(option.value)
                              }
                              onChange={(e: any) => {
                                const isChecked = e.target.checked;
                                let vals = [...(values?.tenure_types || [])];
                                if (isChecked) {
                                  const index = vals.indexOf(option?.value);
                                  if (index < 0) vals.push(option?.value);
                                } else {
                                  vals = [...vals].filter(
                                    (item) => item !== option?.value
                                  );
                                }
                                setFieldValue("tenure_types", vals);
                              }}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </OLMFilterItem>

                  <OLMFilterItem
                    fieldKey="property_status"
                    olmFields={OLM_PROPERTY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center w-full space-x-2">
                      <Label className="w-4/12 mb-6" optional>
                        Property Status
                      </Label>
                      <SelectAutoComplete
                        className="w-full"
                        name="property_status"
                        onChange={(option) => {
                          if (!option) setFieldValue("property_status", null);
                          else setFieldValue("property_status", option?.value);
                        }}
                        onBlur={handleBlur}
                        value={{
                          value: values?.property_status,
                          label:
                            meta?.building?.property_status?.[
                              values?.property_status
                            ]?.value || "",
                        }}
                        error={
                          touched.property_status ? errors.property_status : ""
                        }
                        options={[
                          ...getMetaOptions(meta?.building?.property_status),
                        ]}
                      />
                    </div>
                  </OLMFilterItem>

                  <OLMFilterItem
                    fieldKey="property_sub_types"
                    olmFields={OLM_PROPERTY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center w-full space-x-2">
                      <Label className="w-4/12 mb-6" optional>
                        Property Sub Type
                      </Label>
                      <SelectAutoComplete
                        className="w-full"
                        name="property_sub_types"
                        isMulti
                        onChange={(value) => {
                          setFieldValue("property_sub_types", value);
                        }}
                        onBlur={handleBlur}
                        value={values?.property_sub_types}
                        error={
                          touched.property_sub_types
                            ? errors.property_sub_types
                            : ""
                        }
                        options={[
                          ...getMetaOptions(meta?.building?.property_sub_types),
                        ]}
                      />
                    </div>
                  </OLMFilterItem>

                  <OLMFilterItem
                    fieldKey="max_surface_area"
                    olmFields={OLM_PROPERTY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center w-full space-x-2">
                      <div className="w-4/12 mb-6 flex flex-row items-center justify-between">
                        <Label>{isLand ? "Lot Size" : "Building Size"}</Label>
                        {values?.surface_area_unit === "acres" && (
                          <Switch
                            enabled={isAcresRange}
                            onChange={(flag: boolean) => {
                              setIsAcresRange(flag);
                              if (!flag)
                                setFieldValue("min_surface_area", null);
                              else
                                setFieldValue(
                                  "min_surface_area",
                                  property?.min_surface_area
                                );
                            }}
                            className="!mb-0 h-6"
                          />
                        )}
                      </div>
                      <div className="flex flex-row space-x-2 w-full">
                        <div className="flex flex-row space-x-2 w-full">
                          <Input
                            name="max_surface_area"
                            placeholder={
                              isLand
                                ? `${
                                    values?.surface_area_unit === "acres" &&
                                    isAcresRange
                                      ? "Max "
                                      : ""
                                  }Lot Size`
                                : `${
                                    values?.surface_area_unit === "acres" &&
                                    isAcresRange
                                      ? "Max "
                                      : ""
                                  }Building Size`
                            }
                            type="number"
                            className="w-full"
                            error={
                              touched.max_surface_area
                                ? errors.max_surface_area
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.max_surface_area}
                          />
                          {values?.surface_area_unit === "acres" &&
                            isAcresRange && (
                              <Input
                                placeholder={
                                  isLand ? "Min Lot Size" : "Min Building Size"
                                }
                                name="min_surface_area"
                                className="w-full"
                                error={
                                  touched.min_surface_area
                                    ? errors.min_surface_area
                                    : ""
                                }
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.min_surface_area}
                              />
                            )}
                        </div>
                        <SelectAutoComplete
                          className="w-[200px]"
                          name="surface_area_unit"
                          onChange={(option) => {
                            if (!option)
                              setFieldValue("surface_area_unit", null);
                            else
                              setFieldValue("surface_area_unit", option.value);

                            if (option?.value === "acres")
                              setFieldValue(
                                "min_surface_area",
                                property?.min_surface_area
                              );
                            else setFieldValue("min_surface_area", null);
                          }}
                          value={{
                            value: values?.surface_area_unit
                              ? values?.surface_area_unit
                              : isLand
                              ? "acres"
                              : "feet",
                            label: values?.surface_area_unit
                              ? meta?.building?.surface_area_unit[
                                  values?.surface_area_unit
                                ].value
                              : isLand
                              ? meta?.building?.surface_area_unit["acres"]
                                  ?.value
                              : meta?.building?.surface_area_unit["feet"]
                                  ?.value,
                          }}
                          onBlur={handleBlur}
                          error={
                            touched.surface_area_unit
                              ? errors.surface_area_unit
                              : ""
                          }
                          options={[
                            ...getMetaOptions(
                              meta?.building?.surface_area_unit
                            ),
                          ]}
                        />
                      </div>
                    </div>
                  </OLMFilterItem>

                  <OLMFilterItem
                    fieldKey="building_class"
                    olmFields={OLM_PROPERTY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center w-full space-x-2">
                      <Label className="w-4/12 mb-6" optional>
                        Building Class
                      </Label>
                      <SelectAutoComplete
                        className="w-full"
                        name="building_class"
                        onChange={(option) => {
                          if (!option) setFieldValue("building_class", null);
                          else setFieldValue("building_class", option?.value);
                        }}
                        onBlur={handleBlur}
                        value={{
                          value: values?.building_class,
                          label: values?.building_class,
                        }}
                        error={
                          touched.building_class ? errors.building_class : ""
                        }
                        options={[
                          ...getMetaOptions(meta?.building?.building_class),
                        ]}
                      />
                    </div>
                  </OLMFilterItem>

                  <OLMFilterItem
                    fieldKey="wls"
                    olmFields={OLM_PROPERTY_FIELDS}
                    isOLM={isOLM}
                  >
                    <div className="flex flex-row items-center w-full space-x-2">
                      <Label className="w-4/12 mb-6" optional>
                        Corporate Accounts (WLS)
                      </Label>
                      <SelectAutoComplete
                        className="w-full"
                        name="wls"
                        onChange={(option) => {
                          if (!option) setFieldValue("wls", null);
                          else setFieldValue("wls", option.value);
                        }}
                        onBlur={handleBlur}
                        value={{
                          value: values?.wls,
                          label: meta?.building?.wls[values?.wls]?.value,
                        }}
                        error={touched.wls ? errors.wls : ""}
                        options={[...getMetaOptions(meta?.building?.wls)]}
                      />
                    </div>
                  </OLMFilterItem>
                </div>
              </AccordionItem>
            </Layout.Section>

            {!isOLM && (
              <Layout.Section>
                <AccordionItem id="parking" label="Parking">
                  <div className="flex flex-col w-full pt-8">
                    <div className="flex flex-row items-center space-x-2">
                      <Label className="w-4/12 mb-6" optional>
                        Parking Ratio
                      </Label>
                      <Input
                        className="w-full"
                        name="parking_ratio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.parking_ratio}
                        error={
                          touched.parking_ratio ? errors.parking_ratio : ""
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center space-x-2">
                      <Label className="w-4/12 mb-6" optional>
                        Parking Type
                      </Label>
                      <SelectAutoComplete
                        className="w-full"
                        name="parking_type"
                        onChange={(option) => {
                          if (!option) setFieldValue("parking_type", null);
                          else setFieldValue("parking_type", option.value);
                        }}
                        onBlur={handleBlur}
                        value={{
                          value: Number(values?.parking_type),
                          label:
                            meta?.building?.parking_type[values?.parking_type]
                              ?.value,
                        }}
                        error={touched.parking_type ? errors.parking_type : ""}
                        options={[
                          ...getMetaNumOptions(meta?.building?.parking_type),
                        ]}
                      />
                    </div>
                    <div className="flex flex-row items-center space-x-2">
                      <Label className="w-4/12 mb-6" optional>
                        Unreserved
                      </Label>
                      <Input
                        className="w-full"
                        type="number"
                        name="parking_cost_unreserved"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        currency="USD"
                        value={values?.parking_cost_unreserved}
                        error={
                          touched.parking_cost_unreserved
                            ? errors.parking_cost_unreserved
                            : ""
                        }
                      />
                    </div>
                    <div className="flex flex-row items-center space-x-2">
                      <Label className="w-4/12 mb-6" optional>
                        Reserved
                      </Label>
                      <Input
                        className="w-full"
                        type="number"
                        name="parking_cost_reserved"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        currency="USD"
                        value={values?.parking_cost_reserved}
                        error={
                          touched.parking_cost_reserved
                            ? errors.parking_cost_reserved
                            : ""
                        }
                      />
                    </div>
                  </div>
                </AccordionItem>
              </Layout.Section>
            )}

            <div className="px-8 fixed bottom-0 left-[360px] right-0 flex justify-end bg-white shadow-detail-status-nav h-[80px] items-center">
              <div className="flex space-x-4">
                <Link
                  to={`/property/${propertyId}/${URLS.PROPERTY.FORM}?tab=${PROPERTY_TABS.CONTACTS}`}
                >
                  <Button variant="secondary" trailingIcon={FiChevronRight}>
                    Next
                  </Button>
                </Link>
                <Button
                  disabled={
                    loading || !diffFields || !Object.keys(diffFields)?.length
                  }
                  variant="primary"
                  onClick={() => onClickSubmit()}
                  id="save-tooltip"
                  onMouseOver={() => showTooltip("save-tooltip")}
                  onMouseLeave={() => hideTooltip()}
                  data-tooltip-content={
                    property?.publish_status === STATUS_ACTIVE
                      ? "This saves the property in MyListings and publishes to OLM"
                      : "This saves the property, but does not publish to OLM"
                  }
                >
                  {property?.publish_status === STATUS_ACTIVE
                    ? "Save and Publish"
                    : "Save"}
                </Button>
              </div>
            </div>
          </form>
        )}
        <WarningModal
          isOpen={isWarningModal}
          setIsOpen={setIsWarningModal}
          errors={errors}
        />
      </>
    );
  }
);

export default React.memo(PropertyEditV2);
