import {
  AVAILABILITIES_TABLE_COLUMNS,
  META_TAGS,
  PROPERTIES_TABLE_COLUMNS,
  REPORT_ACTIVITY_COLUMNES,
  REPORT_MARKET_COLUMNS,
  SEARCH_RESULT_LIMIT,
  USER_GROUP_LEADERSHIP,
} from "constant";
import queryString from "query-string";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { FiDownload, FiRotateCcw, FiSearch } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  DateRangeSelect,
  EmptyResult,
  Input,
  Loading,
  MultipleSelect,
  SearchableMultipleSelect,
  Tabbar,
} from "ui-atoms";
import { AuthHome as Layout } from "ui-organisms";
import { useContext, useEffect, useMemo, useState } from "react";
import ReportChart from "./ReportChart";
import {
  ActivityProps,
  AvailabilityProps,
  DirectionEnum,
  FacetsProps,
  FilteredFacetProps,
  IReportBucket,
  IReportBuckets,
  ISeries,
  PropertyProps,
  ReportTotalDataProps,
} from "types";
import { debounce } from "lodash";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useApiCall } from "hooks";
import {
  AvailabilityPreviewModal,
  StyledAvailabilityValue,
  StyledPropertyValue,
  StyledStalenessValue,
  Table,
} from "ui-molecules";
import {
  capitalize,
  getContactTypeId,
  getFacetStateOptions,
  getFilteredFacets,
  getFormatedDate,
  getFormatedDateTime,
  getOrderedFacets,
  getStateOptions,
} from "utils";
import {
  getBrokersAPI,
  getBuildingStalenessAPI,
  getMarketsAPI,
  getReportDataAPI,
  getReportExportDataAPI,
  getReportsAPI,
  getSuiteStalenessAPI,
  getUsersAPI,
} from "services";
import { GlobalContext } from "context";
import { differenceInDays } from "date-fns";
import React from "react";
import SlidingPane from "react-sliding-pane";
import ReportStalenessPane from "./ReportStalenessPane";

const TabMenu = [
  { label: "Properties", value: "property" },
  { label: "Availabilities", value: "availability" },
  { label: "Visualize", value: "visualize" },
  { label: "Market", value: "market" },
];

const MARKET_TAB_LIST = [
  { label: "Property Staleness", value: "property_staleness" },
  { label: "Availability Staleness", value: "availability_staleness" },
];

const InitChart = {
  series: [],
  categories: [],
};

const getChartData = (res: any) => {
  const filteredData = res?.facets?.dates?.buckets?.filter(
    (bucket: IReportBuckets) => !!bucket?.type?.buckets?.length
  );
  let xAxisData: string[] = [];
  let seriesData: ISeries[] = [];
  filteredData?.forEach((item: IReportBuckets, index: number) => {
    xAxisData.push(getFormatedDate(item?.val));
    item?.type?.buckets?.forEach((bucket: IReportBucket, idx) => {
      const seriesIndex = seriesData?.findIndex(
        (series, idx2) => series?.name === bucket?.val
      );
      if (seriesIndex < 0) {
        let data = [];
        data[index] = bucket?.count;
        seriesData.push({ name: bucket?.val, data: data });
      } else {
        let data = seriesData[seriesIndex].data;
        data[index] = bucket?.count;
        seriesData[seriesIndex].data = data;
      }
    });
  });
  return [xAxisData, seriesData];
};

const facetSortList = [
  "coordinator",
  "broker",
  "global_market",
  "state",
  "market",
  "sub_market",
  "activity_type",
];

const Reports = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const [getReportData, isLoading] = useApiCall(getReportDataAPI);
  const [getReports] = useApiCall(getReportsAPI);
  const [getReportExportData] = useApiCall(getReportExportDataAPI);
  const [getBuildingStaleness] = useApiCall(getBuildingStalenessAPI);
  const [getSuiteStaleness] = useApiCall(getSuiteStalenessAPI);
  const [getUsers] = useApiCall(getUsersAPI);
  const [getBrokers] = useApiCall(getBrokersAPI);
  const [getMarkets] = useApiCall(getMarketsAPI);
  const [activeTab, setActiveTab] = useState<any>("property");
  const [keyword, setKeyword] = useState<string>();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any>([]);
  const [facets, setFacets] = useState<FilteredFacetProps[]>();
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [propertyChart, setPropertyChart] = useState<any>(InitChart);
  const [availabilityChart, setAvailabilityChart] = useState<any>(InitChart);
  const [isAvailabilityOpen, setIsAvailabilityOpen] = useState(false);
  const [openedAvailability, SetOpenedAvailability] = useState<number>(-1);
  const [isExporting, setIsExporting] = useState(false);
  const [marketSubTab, setMarketSubTab] = useState("property_staleness");
  const [isSlidePane, setIsSlidePane] = useState(false);
  const [selectedStaleness, setSelectedStaleness] = useState({});
  const [filteredStalenessData, setFilteredStalenessData] = useState([]);
  const [stalenessKeyword, setStalenessKeyword] = useState("");
  const [stalenessSort, setStalenessSort] = useState<any>({
    sort: "name",
    direction: undefined,
  });

  let columns: any[] = [];

  if (activeTab === "property") {
    const filteredColumns = [...PROPERTIES_TABLE_COLUMNS].filter(
      (item) => item?.id !== "updated_info"
    );
    columns = [...filteredColumns, ...REPORT_ACTIVITY_COLUMNES];
  } else if (activeTab === "availability") {
    const filteredColumns = [...AVAILABILITIES_TABLE_COLUMNS].filter(
      (item) => item?.id !== "updated_info"
    );
    columns = [...filteredColumns, ...REPORT_ACTIVITY_COLUMNES];
  } else if (activeTab === "market") {
    columns = [...REPORT_MARKET_COLUMNS];
  }

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    if (filters?.tab) {
      setActiveTab(filters?.tab?.toString()?.toLowerCase());
    }
    if (filters?.market_tab) {
      setMarketSubTab(filters?.market_tab?.toString()?.toLowerCase());
    }
    setKeyword(filters?.keyword?.toString() || "");
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    if (!meta || !paramFilters) return;
    setKeyword(paramFilters?.keyword || "");
    setPage(1);

    if (activeTab === "property" || activeTab === "availability") {
      setData([]);
      let content_type;
      if (activeTab === "property")
        content_type = getContactTypeId(meta, "building");
      else if (activeTab === "availability")
        content_type = getContactTypeId(meta, "suite");
      const updatedFilters = { ...paramFilters };
      if (updatedFilters.hasOwnProperty("tab")) delete updatedFilters.tab;
      getReportData({
        page: 1,
        content_type,
        limit: SEARCH_RESULT_LIMIT,
        ...updatedFilters,
      }).then((res: any) => {
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
        setFacets(
          getOrderedFacets(
            getFilteredFacets(res?.facets as FacetsProps),
            facetSortList
          )
        );
        setSortFields(res?.sort_fields);
      });
    } else if (activeTab === "visualize") {
      setPropertyChart(InitChart);
      setAvailabilityChart(InitChart);
      let additionalParam = { ...paramFilters };
      const updatedFilters = { ...paramFilters };
      delete additionalParam.tab;
      let gap_days = 7;
      if (
        updatedFilters?.hasOwnProperty("start_date") &&
        updatedFilters?.hasOwnProperty("end_date")
      ) {
        const diffDays = differenceInDays(
          new Date(updatedFilters?.end_date),
          new Date(updatedFilters?.start_date)
        );
        if (diffDays < 7) {
          gap_days = 1;
        } else if (gap_days / 7 / 12 > 1) {
          gap_days = 7 * Math.ceil(gap_days / 7 / 12);
        }
      }
      getReports({
        ...additionalParam,
        content_type: getContactTypeId(meta, "building"),
        gap_days,
      }).then((res: any) => {
        const [xAxisData, seriesData] = getChartData(res);
        setPropertyChart({ series: seriesData, categories: xAxisData });
        const filteredFaces = { ...res?.facets };
        delete filteredFaces.dates;
        setFacets(
          getOrderedFacets(
            getFilteredFacets(filteredFaces as FacetsProps),
            facetSortList
          )
        );
      });
      getReports({
        ...additionalParam,
        content_type: getContactTypeId(meta, "suite"),
        gap_days,
      }).then((res: any) => {
        const [xAxisData, seriesData] = getChartData(res);
        setAvailabilityChart({ series: seriesData, categories: xAxisData });
        const filteredFaces = { ...res?.facets };
        delete filteredFaces.dates;
        setFacets(
          getOrderedFacets(
            getFilteredFacets(filteredFaces as FacetsProps),
            facetSortList
          )
        );
      });
    } else if (activeTab === "market") {
      setData([]);
      const updatedFilters = { ...paramFilters };
      if (marketSubTab === "property_staleness") {
        getBuildingStaleness({
          page: 1,
          limit: SEARCH_RESULT_LIMIT,
          ...updatedFilters,
        }).then((res: any) => {
          if (!res) return;
          setData(res?.data);
          setTotal(res?.data?.length);
          setFacets([]);
          setSortFields([]);
        });
      } else {
        getSuiteStaleness({
          page: 1,
          limit: SEARCH_RESULT_LIMIT,
          ...updatedFilters,
        }).then((res: any) => {
          if (!res) return;
          setData(res?.data);
          setTotal(res?.data?.length);
          setFacets([]);
          setSortFields([]);
        });
      }
    }
  }, [paramFilters, activeTab, meta]);

  useEffect(() => {
    if (
      activeTab === "market" &&
      !session?.groups?.find(
        (group: any) => group?.id === USER_GROUP_LEADERSHIP
      )
    ) {
      navigate(`${location.pathname}`);
    }
  }, [session]);

  useEffect(() => {
    if (activeTab !== "market" || !data) return;
    setFilteredStalenessData(data);
    setStalenessKeyword("");
    setSortFields(["name"]);
    setStalenessSort({ sort: "name", direction: undefined });
  }, [data]);

  const handleTab = (value: string) => () => {
    const updatedFilters = { tab: value };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const handleMarketTab = (tab: string) => {
    const updatedFilters = { tab: "market", market_tab: tab };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const updateKeywordFilter = (e: any) => {
    const filters = queryString.parse(location?.search);
    const updatedFilters = { ...filters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters?.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const onClickReset = () => {
    const filters = { ...queryString.parse(location?.search) };
    let newFilter: any = {};
    if (filters?.hasOwnProperty("tab")) {
      newFilter["tab"] = filters?.tab;
    }
    navigate(`${location.pathname}?${queryString.stringify(newFilter)}`);
  };

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const loadMore = () => {
    if (activeTab === "market") return;
    const nextPage = page + 1;
    setPage(nextPage);
    let content_type;
    if (activeTab === "property")
      content_type = getContactTypeId(meta, "building");
    else if (activeTab === "availability")
      content_type = getContactTypeId(meta, "suite");
    const updatedFilters = { ...paramFilters };
    if (updatedFilters.hasOwnProperty("tab")) delete updatedFilters.tab;
    getReportData({
      page: nextPage,
      content_type,
      limit: SEARCH_RESULT_LIMIT,
      ...updatedFilters,
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
      setFacets(
        getOrderedFacets(
          getFilteredFacets(res?.facets as FacetsProps),
          facetSortList
        )
      );
      setSortFields(res?.sort_fields);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const handleDateRange = ({ start_date, end_date }: any) => {
    let updatedFilters = {
      ...paramFilters,
    };
    if (start_date)
      updatedFilters = {
        ...updatedFilters,
        start_date,
      };
    if (end_date)
      updatedFilters = {
        ...updatedFilters,
        end_date,
      };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const onClickPreview = (e: any, pk: AvailabilityProps["pk"]) => {
    e.stopPropagation();
    SetOpenedAvailability(pk);
    setIsAvailabilityOpen(true);
  };

  const downloadFile = async () => {
    try {
      setIsExporting(true);
      let content_type;
      if (activeTab === "property")
        content_type = getContactTypeId(meta, "building");
      else if (activeTab === "availability")
        content_type = getContactTypeId(meta, "suite");
      const updatedFilters = { ...paramFilters };
      if (updatedFilters.hasOwnProperty("tab")) delete updatedFilters.tab;

      const exportData = await getReportExportData({
        content_type,
        ...updatedFilters,
      });

      const fileData = new Blob([exportData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileUrl = URL.createObjectURL(fileData);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `Export ${capitalize(activeTab)}- ${getFormatedDateTime(
        new Date().toString()
      )}`;
      link.click();
      URL.revokeObjectURL(fileUrl);
      setIsExporting(false);
    } catch (err) {
      setIsExporting(false);
    }
  };

  const handleStalenessFilterData = (keyword: string) => {
    setStalenessKeyword(keyword);
    if (activeTab !== "market") return;
    const new_data = data?.filter((item: any) =>
      item?.name?.toLowerCase().includes(keyword?.toLowerCase())
    );
    setFilteredStalenessData(new_data);
  };

  const onClickStalenssSorting = (
    sort: string,
    direction: DirectionEnum | undefined
  ) => {
    const new_filter = [...filteredStalenessData]?.sort((a: any, b: any) => {
      if (direction === DirectionEnum.asc) {
        setStalenessSort({ ...stalenessSort, direction: direction });
        return a?.[sort] > b?.[sort] ? 1 : b?.[sort] > a?.[sort] ? -1 : 0;
      } else {
        setStalenessSort({ ...stalenessSort, direction: direction });
        return a?.[sort] < b?.[sort] ? 1 : b?.[sort] < a?.[sort] ? -1 : 0;
      }
    });
    setFilteredStalenessData(new_filter);
  };

  return (
    <Layout>
      <Helmet prioritizeSeoTags>
        <title>{META_TAGS?.reports?.title}</title>
        <meta property="og:title" content={META_TAGS?.reports?.title} />
      </Helmet>
      <div className="fixed top-[64px] right-0 bottom-0 left-0 bg-white shadow-detail-status-nav px-0">
        <div className="relative w-full h-full">
          {activeTab !== "market" && (
            <Layout.Navbar className="!h-fit py-4 px-8 shadow-detail-status-nav">
              <div className="flex flex-row items-center space-x-2">
                <div className="flex flex-row space-x-2">
                  {facets?.map((facetCategory, facet_index) => {
                    // if (!facetCategory.facets?.length) {
                    //   return null;
                    // }
                    if (facetCategory?.id === "coordinator") {
                      return (
                        <SearchableMultipleSelect
                          key={facet_index}
                          name={facetCategory?.id}
                          title={facetCategory?.label}
                          serviceAPI={getUsers}
                        />
                      );
                    }

                    if (facetCategory?.id === "broker") {
                      return (
                        <SearchableMultipleSelect
                          key={facet_index}
                          name={facetCategory?.id}
                          title={facetCategory?.label}
                          serviceAPI={getBrokers}
                        />
                      );
                    }

                    if (facetCategory?.id === "state") {
                      if (!meta) return;
                      const options = getStateOptions(meta);
                      if (!options) return;
                      return (
                        <SearchableMultipleSelect
                          name="state"
                          title="State"
                          options={options}
                        />
                      );
                    }

                    if (facetCategory?.id === "market") {
                      const data = facetCategory?.facets;
                      if (!data) return;
                      return (
                        <SearchableMultipleSelect
                          name={facetCategory?.id}
                          title={facetCategory?.label}
                          serviceAPI={getMarkets}
                          isMissing={false}
                        />
                      );
                    }

                    return (
                      <MultipleSelect
                        key={facet_index}
                        options={facetCategory?.facets}
                        selectedOptions={facetCategory?.facets?.filter(
                          (facet) => {
                            if (
                              facet?.value?.toString()?.includes("_missing")
                            ) {
                              return !!paramFilters[facet?.value];
                            }
                            return (
                              paramFilters?.[facetCategory?.id]?.includes(
                                facet?.value?.toString()
                              ) ||
                              paramFilters?.[facetCategory?.id]?.includes(
                                facet?.value
                              )
                            );
                          }
                        )}
                        name={facetCategory?.id}
                        title={facetCategory?.label}
                      />
                    );
                  })}
                </div>
                <DateRangeSelect
                  title="Select Date"
                  onChange={handleDateRange}
                  start_date={paramFilters?.start_date}
                  end_date={paramFilters?.end_date}
                  isRight
                />
                {paramFilters &&
                  !!Object.keys(paramFilters)?.filter(
                    (key) => key !== "tab" && key !== "activity_keyword"
                  )?.length && (
                    <Button
                      variant="ghost"
                      size="small"
                      leadingIcon={FiRotateCcw}
                      onClick={onClickReset}
                    >
                      Reset
                    </Button>
                  )}
              </div>

              <Input
                className="w-[400px] !mb-0"
                name="search"
                leadingIcon={FiSearch}
                onChange={onChangeInput}
                value={keyword}
                isClearable
                placeholder={`Search ${activeTab}`}
              />
            </Layout.Navbar>
          )}

          <Layout.Navbar className="border-b border-jll-color-coldGray-3 px-8 !h-[70px]">
            <div className="flex flex-row space-x-8 h-full">
              {TabMenu?.map((tab: any, idx: number) => {
                if (
                  tab?.value === "market" &&
                  !session?.groups?.find(
                    (group: any) => group?.id === USER_GROUP_LEADERSHIP
                  )
                )
                  return;
                return (
                  <div
                    className={cn(
                      "cursor-pointer font-semibold text-base flex items-center",
                      {
                        "text-jll-color-text-base-default border-b border-jll-color-coldGray-7":
                          activeTab?.toLowerCase() === tab.value,
                        "text-jll-color-coldGray-5":
                          activeTab?.toLowerCase() !== tab.value,
                      }
                    )}
                    key={idx}
                    onClick={handleTab(tab?.value)}
                  >
                    {tab?.label}
                  </div>
                );
              })}
            </div>
            {activeTab !== "market" && (
              <Button
                variant="primary"
                leadingIcon={FiDownload}
                leadingIconClass="font-bold"
                disabled={isExporting}
                onClick={downloadFile}
              >
                Export Data
              </Button>
            )}
          </Layout.Navbar>

          <div
            className={cn("absolute w-full  overflow-hidden bottom-0", {
              "top-[140px]": activeTab !== "market",
              "top-[70px]": activeTab === "market",
            })}
          >
            {activeTab === "visualize" ? (
              <div className="flex flex-row w-full h-full">
                <div className="w-6/12 h-full">
                  <ReportChart
                    label="Property Events"
                    className="pr-7 pl-8"
                    series={propertyChart?.series}
                    categories={propertyChart?.categories}
                  />
                </div>
                <div className="w-6/12 h-full">
                  <ReportChart
                    label="Availability Events"
                    className="pl-7 pr-8 bg-jll-color-coldGray-1"
                    series={availabilityChart?.series}
                    categories={availabilityChart?.categories}
                  />
                </div>
              </div>
            ) : (
              <div className="px-8 py-5 overflow-auto w-full h-full">
                {activeTab === "market" ? (
                  <div className="mb-4 flex flex-row items-center space-x-6 justify-between">
                    <div className="flex flex-row items-center space-x-6">
                      <Tabbar
                        tabList={MARKET_TAB_LIST}
                        activeTab={marketSubTab}
                        setActiveTab={handleMarketTab}
                      />
                      <span className="font-semibold text-jll-color-coldGray-7 text-sm">
                        {total} result{total > 1 ? "s" : ""}
                      </span>
                    </div>

                    <Input
                      className="w-[400px] !mb-0"
                      name="search"
                      leadingIcon={FiSearch}
                      onChange={(e: any) =>
                        handleStalenessFilterData(e?.target?.value)
                      }
                      value={stalenessKeyword}
                      isClearable
                      placeholder="Search Staleness"
                    />
                  </div>
                ) : (
                  <div className="flex space-x-4 pb-0 justify-between">
                    <div className="font-semibold text-jll-color-coldGray-7 text-sm">
                      {total} result{total > 1 ? "s" : ""}
                    </div>
                  </div>
                )}
                {!!isLoading && !data?.length && <Loading />}
                {!isLoading &&
                  ((activeTab !== "market" && !data?.length) ||
                    (activeTab === "market" &&
                      !filteredStalenessData?.length)) && (
                    <EmptyResult>
                      {activeTab === "availability" && (
                        <>
                          <h1 className="text-jll-color-coldGray-3">
                            {session?.get_full_name
                              ? `Welcome, ${session?.get_full_name}`
                              : ""}
                          </h1>
                          <p className="mt-4 text-body1 text-jll-color-coldGray-7 mb-3 text-center">
                            You don’t have any availability listings. You can
                            browse existing availabilities{" "}
                            <Link
                              className="text-jll-color-icon-info"
                              to={"/availabilities?active_status[]=1"}
                            >
                              here
                            </Link>{" "}
                            or add properties by searching MarketSphere below:
                          </p>
                          <div className="flex space-x-4">
                            <Link to="/properties">
                              <Button variant="primary" leadingIcon={FiSearch}>
                                Browse Listings
                              </Button>
                            </Link>
                          </div>
                        </>
                      )}
                      {activeTab === "property" && (
                        <>
                          <h1 className="text-jll-color-coldGray-3">
                            {session?.get_full_name
                              ? `Welcome, ${session?.get_full_name}`
                              : ""}
                          </h1>
                          <p className="mt-4 text-body1 text-jll-color-coldGray-7 mb-3 text-center">
                            You don’t have any property listings. To get
                            started, please click the add property to add a
                            Property to My Listings.
                          </p>
                        </>
                      )}
                      {activeTab === "market" && (
                        <>
                          <h1 className="text-jll-color-coldGray-3">
                            {session?.get_full_name
                              ? `Welcome, ${session?.get_full_name}`
                              : ""}
                          </h1>
                          <p className="mt-4 text-body1 text-jll-color-coldGray-7 mb-3 text-center">
                            Can't find the staleness data
                          </p>
                        </>
                      )}
                    </EmptyResult>
                  )}
                {((activeTab !== "market" && !!data?.length) ||
                  (activeTab === "market" &&
                    !!filteredStalenessData?.length)) && (
                  <>
                    <Table>
                      <Table.Thead>
                        <Table.Tr>
                          {columns?.map((column, index) => {
                            return (
                              <Table.Th
                                key={index}
                                className={column?.th?.className}
                                filterId={column.id}
                                sortFields={sortFields}
                                filters={
                                  activeTab === "market"
                                    ? stalenessSort
                                    : paramFilters
                                }
                                onClick={
                                  activeTab === "market"
                                    ? onClickStalenssSorting
                                    : onClickSorting
                                }
                              >
                                {column.label}
                              </Table.Th>
                            );
                          })}
                          {activeTab === "availability" && <Table.Th />}
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        {(activeTab === "market"
                          ? filteredStalenessData
                          : data
                        )?.map((item: any, index: number) => {
                          return (
                            <Table.Tr
                              key={index}
                              onClick={
                                activeTab === "market"
                                  ? () => {
                                      setIsSlidePane(true);
                                      setSelectedStaleness({
                                        ...item,
                                        type:
                                          marketSubTab === "property_staleness"
                                            ? "property"
                                            : "suite",
                                      });
                                    }
                                  : () => {}
                              }
                            >
                              {columns.map((column, index) => {
                                return (
                                  <Table.Td
                                    key={index}
                                    className={column?.td?.className}
                                  >
                                    {activeTab === "availability" && (
                                      <StyledAvailabilityValue
                                        availability={
                                          item?.object as AvailabilityProps
                                        }
                                        activity={item as ActivityProps}
                                        valueKey={column.id}
                                      />
                                    )}
                                    {activeTab === "property" && (
                                      <StyledPropertyValue
                                        property={item?.object as PropertyProps}
                                        activity={item as ActivityProps}
                                        valueKey={column.id}
                                      />
                                    )}
                                    {activeTab === "market" && (
                                      <StyledStalenessValue
                                        staleness={item as ReportTotalDataProps}
                                        type={
                                          marketSubTab === "property_staleness"
                                            ? "property"
                                            : "suite"
                                        }
                                        valueKey={column.id}
                                      />
                                    )}
                                  </Table.Td>
                                );
                              })}
                              {activeTab === "availability" && (
                                <Table.Td className="text-right">
                                  <Button
                                    variant="secondary"
                                    size="small"
                                    onClick={(e) => {
                                      onClickPreview(
                                        e,
                                        item?.suite?.pk || item?.object?.pk
                                      );
                                    }}
                                  >
                                    Preview
                                  </Button>
                                </Table.Td>
                              )}
                            </Table.Tr>
                          );
                        })}
                      </Table.Tbody>
                    </Table>
                    {!!(total > data?.length) && activeTab !== "market" && (
                      <div
                        className="w-full py-2 flex justify-center items-center"
                        ref={sentryRef}
                      >
                        <Loading />
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <AvailabilityPreviewModal
        pk={openedAvailability}
        isOpen={isAvailabilityOpen}
        setIsOpen={setIsAvailabilityOpen}
      />
      <SlidingPane
        width="80%"
        from="right"
        className="staleness-pane"
        isOpen={isSlidePane}
        onRequestClose={() => setIsSlidePane(false)}
      >
        <ReportStalenessPane {...selectedStaleness} />
      </SlidingPane>
    </Layout>
  );
};

export default React.memo(Reports);
