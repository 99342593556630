import queryString from "query-string";
import axios from "../axiosConfig";
import { APIProps, CommentProps } from "types";
import { API_PREFIX_URL, STATUS_ACTIVE } from "constant";

export const getPropertiesAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/buildings/?${param_str}`);
  return res;
};

export const getPropertiesExcelAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/buildings/?${param_str}`, {
    responseType: "blob",
  });
  return res;
};

export const getPropertyAPI = async (id: string) => {
  // Calling building/id with active_status[]=1 will return
  // the all building details and active suite instead all all suites
  const res = await axios.get(`${API_PREFIX_URL}/buildings/${id}/`);
  return res;
};

// TODO: can't put any type for data
export const putPropertyAPI = async (id: string, data: any) => {
  const res = await axios.put(`${API_PREFIX_URL}/buildings/${id}/`, data);
  return res;
};

// TODO: can't put any type for data
export const patchPropertyAPI = async ({
  id,
  payload,
}: {
  id: number;
  payload: any;
}) => {
  const res = await axios.patch(`${API_PREFIX_URL}/buildings/${id}/`, payload);
  return res;
};

export const postDuplicatePropertyToLanguage = async (payload: {
  property_id: string;
  property_language: string;
  destination_language: string;
}) => {
  const res = await axios.post(
    `${API_PREFIX_URL}/building/duplicate-to-lang/`,
    payload
  );
  return res;
};

export const postPropertyBrokerAPI = async ({
  role,
  entity_id,
  broker_id,
}: {
  role: string;
  entity_id: number;
  broker_id: number;
}) => {
  const res = await axios.post(`${API_PREFIX_URL}/building/broker/`, {
    role: role,
    building: entity_id,
    broker: broker_id,
  });
  return res;
};

export const deletePropertyBrokerAPI = async (id: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/building/broker/${id}/`);
  return res;
};

export const postPropertyDescriptionAPI = async (data: any) => {
  const res = await axios.post(
    `${API_PREFIX_URL}/building/descriptions/`,
    data
  );
  return res;
};

export const putPropertyDescriptionAPI = async (id: number) => {
  const res = await axios.put(`${API_PREFIX_URL}/building/descriptions/${id}/`);
  return res;
};

export const patchPropertyDescriptionAPI = async ({
  id,
  payload,
}: {
  id: number;
  payload: any;
}) => {
  const res = await axios.patch(
    `${API_PREFIX_URL}/building/descriptions/${id}/`,
    payload
  );
  return res;
};

export const deletePropertyDescriptionAPI = async (id: number) => {
  const res = await axios.delete(
    `${API_PREFIX_URL}/building/descriptions/${id}/`
  );
  return res;
};

export const getPropertyCommentsAPI = async (
  id: number,
  active_status = STATUS_ACTIVE
) => {
  const res = await axios.get(
    `${API_PREFIX_URL}/building/comments/?building[]=${id}&active_status[]=${active_status}`
  );
  return res;
};

export const getPropertyAIAPI = async (id: number) => {
  const res = await axios.get(
    `${API_PREFIX_URL}/ai/building-description/${id}/`
  );
  return res;
};

export const postPropertyAIAPI = async (payload: any) => {
  const res = await axios.post(
    `${API_PREFIX_URL}/ai/building-description/`,
    payload
  );
  return res;
};

export const postPropertyCommentAPI = async (payload: {
  updated_user: number;
  comment: string;
  building: number;
}) => {
  const res = await axios.post(`${API_PREFIX_URL}/building/comments/`, payload);
  return res;
};

export const deletePropertyCommentAPI = async (id: CommentProps["pk"]) => {
  const res = await axios.delete(`${API_PREFIX_URL}/building/comments/${id}/`);
  return res;
};

export const getPropertyMapAPI = async (payload: any) => {
  const props = { ...payload };
  if (props?.id) delete props.id;
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(
    `${API_PREFIX_URL}/building/map/${payload?.id}/?${param_str}`
  );
  return res;
};
